import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/digital-transformation-consulting/Banner"
import Choose from "../components/digital-transformation-consulting/Choose"
import Transformation from "../components/digital-transformation-consulting/Transformation"
import Services from "../components/digital-transformation-consulting/Services"
import ServicesMobile from "../components/digital-transformation-consulting/ServicesMobile"
import Process from "../components/digital-transformation-consulting/Process"
import Faqs from "../components/common/Faqs2"

export const Head = ({ data }) => {
  //   const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      {/* <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      /> */}
    </>
  )
}
const digitalTransformation = ({ data }) => {
  console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const choose = data?.strapiPage?.sections[1]
  const transformation = data?.strapiPage?.sections[2]
  const services = data?.strapiPage?.sections[3]
  const faq = data?.strapiPage?.sections[5]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <Choose strapiData={choose} />
      <Transformation strapiData={transformation} />
      <Services strapiData={services} />
      <ServicesMobile strapiData={services} />
      <Process />
      {/* {!isMobile && !isTablet ? (
        <Services strapiData={services} />
      ) : (
        <ServicesMobile strapiData={services} />
      )}
      <Partnering strapiData={partnering} /> */}
      <Faqs strapiData={faq} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query digitalTransformation {
    strapiPage(slug: { eq: "digital-transformation-consulting" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default digitalTransformation
