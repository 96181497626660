import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from "gatsby"
import "./Process.scss"
import * as styles from "./Process.module.scss"
import { Container } from "react-bootstrap"

gsap.registerPlugin(ScrollTrigger)

const Process = () => {
  const containerRef = useRef(null)
  const cardsRef = useRef(null)

  useEffect(() => {
    let totalWidth = 0

    if (cardsRef.current) {
      totalWidth = cardsRef.current.scrollWidth - window.innerWidth
    }

    // Horizontal scroll animation for cards
    gsap.to(cardsRef.current, {
      // x: () => -totalWidth + window.innerWidth / 2, // Adjust to move cards appropriately
      x: -totalWidth,
      ease: "none",
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: () => `+=${totalWidth}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
      },
    })

    return () => {
      ScrollTrigger.killAll()
    }
  }, [])

  const data = [
    {
      heading: "Getting on the same page",
      subheading:
        "Initially, we start with devoted workshops with the potential stakeholders to identify major KPIs of the upcoming projects.",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/startup_8a2b665761.webp",
    },
    {
      heading: "Product dissection",
      subheading:
        "A detailed discussion with the owners of the product and technical team is held while keeping each fact documented.",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/enterprise_3e1ec4533a.webp",
    },
    {
      heading: "MVP scoping settlement",
      subheading:
        "We then finalize all the featured tech stakes, identify the scope of the project, and bring developers on board.",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/rescue_9f1a622c97.webp",
    },
    {
      heading: "Feedback & alignment",
      subheading:
        "We keep ourselves in the loop with the feedback and conversions we’re getting as a result of our struggles.",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/rescue_9f1a622c97.webp",
    },
    {
      heading: "Staffing & Allocation",
      subheading: "I need a Rescue",
      image1:
        "A full-fledged project plan is provided with the ideal resource allocation. We further get in touch to figure out the future deliverables.",
    },
  ]

  return (
    <div className={styles.backing} ref={containerRef}>
      <Container>
        <p className={styles.ser}>Process</p>
        <h2 className={styles.mainHeading}>Our Highly Toned Process</h2>
      </Container>
      <div className={styles.horizontalscrollcards} ref={cardsRef}>
        {data.map((e, i) => (
          <div
            className={
              i === 0
                ? styles.card1
                : i === 1
                ? styles.card2
                : i === 2
                ? styles.card3
                : i === 3
                ? styles.card4
                : i === 4
                ? styles.card5
                : ""
            }
          >
            <div className={styles.headings}>
            <p className={styles.heading}>{e?.heading}</p>
            <p className={styles.subheading}>{e?.subheading}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Process
