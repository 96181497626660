// extracted by mini-css-extract-plugin
export var backing = "Process-module--backing--7c97e";
export var card1 = "Process-module--card1--efc65";
export var card2 = "Process-module--card2--72669";
export var card3 = "Process-module--card3--afe38";
export var card4 = "Process-module--card4--942b7";
export var card5 = "Process-module--card5--69d52";
export var heading = "Process-module--heading--b9ead";
export var headings = "Process-module--headings--49732";
export var horizontalscrollcards = "Process-module--horizontalscrollcards--547e7";
export var mainHeading = "Process-module--mainHeading--5ab3c";
export var ser = "Process-module--ser--00c2e";
export var subheading = "Process-module--subheading--3be04";